import React from "react"
import * as Styles from "./contentmoduleAbs.module.css"

export default function ContentmoduleAbs(props) {
  
    return (
        <div className={`${Styles.wrap}`} >
            <div>
                {props.children}            
            </div>                
        </div>
    )
  }