import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SectionWrap from "../../../components/sectionWrap"
import Introtext from "../../../components/introtext"
import Headline from "../../../components/headline"
import * as ffsStyles from "../../../components/sectionWrap/sectionWrap.module.css"
import ContentmoduleAbs from "../../../components/contentmoduleAbs"
import WhatsNext from "../../../components/whatsNext/whatsnext"
import {AnchorLink} from "gatsby-plugin-anchor-links"
// Background-Images
import firstBackgroundImage from "../../../images/greyback.jpg"
import secondBackgroundImage from "../../../images/whiteback.jpg"
import thirdBackgroundImage from "../../../images/greyback.jpg"

// Objekt-Images
import firstObjectImage from "../../../images/projekte/buderus-partnerwelt/landing/projekte-b2b-plattforms-partnerwelt-level.png"
import secondObjectImage from "../../../images/projekte/bje-excellence-portal/landing/excellence-landing.png"
import thirdObjectImage from "../../../images/projekte/congstar-partnernet/landing/congstar-landing.png"

const BTOBPlatformIndex = function (props, ref) {
  var counter = 0
  const data = [
    {
      logo: "/logos/logo-buderus.svg",
      titel: "Buderus Partnerwelt",
      text: "Ganzheitliches Kundenbindungs- und Kundenausbauprogramm für Buderus Deutschland / Bosch Thermotechnik GmbH.",
      slug: "/projekte/b2b-platforms/buderus-partnerwelt",
      objectImage: firstObjectImage,
      bgImage: firstBackgroundImage,
    },
    {
      logo: "/logos/logo-bje.svg",
      titel: "Busch-Jaeger Excellence",
      text: "Portal zur Unterstützung der Marketing- und Vertriebsmaßnahmen für Marktpartner von Busch-Jaeger.",
      slug: "/projekte/b2b-platforms/busch-jaeger-excellence",
      objectImage: secondObjectImage,
      bgImage: secondBackgroundImage,
    },
    {
      logo: "/logos/logo-congstar.svg",
      titel: "congstar Partnernet",
      text: "Downloadportal für Vertriebspartner und Kommunikationsdienstleister.",
      slug: "/projekte/b2b-platforms/congstar-partnernet",
      objectImage: thirdObjectImage,
      bgImage: thirdBackgroundImage,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Projekte: B2B Platforms"
        description="das ist eine beschreibung der marketingtools-seite"
      />

      <div className="page-container-offset">
        {/* 
          head
        */}
        <div className="container-fluid">
          <SectionWrap id="abc" bgCol={"#fff"} className="height50 my-5">
            <div className={`row ${ffsStyles.vcenterRowContent}`}>
              {/* left */}
              <div className={`col-12 col-md-8 px10`}>
                <Headline
                  titel="B2B platforms"
                  showCta={false}
                  skyhead={false}
                  text={<>Mit innovativen Kundenbindungs- und Vertriebspartnerprogrammen sichern wir Ihren Markterfolg in mehrstufigen Vertriebsstrukturen.<p className="pt-2"><AnchorLink to="/projekte/b2b-platforms/#beispiele" title="↓ Beispiele" className="" /></p></>}
                />
              </div>
            </div>
          </SectionWrap>
        </div>

        <div id="beispiele"></div>        

        {data.map(e => {
          counter++
          var result = counter % 2 ? true : false

          return (
            <div className="container-fluid">
              <SectionWrap bgImg={e.bgImage} bgCol={"#fff"} className="height80 projektdetailkachel">
                <div className={`row ${ffsStyles.vcenterRowContent} bg-q-grey-mobile`}>
                  <div className={`col-12 mx-0 px-0`}>
                    {/* Vordergrund */}
                    <ContentmoduleAbs>
                      <div className={`row ${ffsStyles.vcenterRowContent}`}>
                        <div className={`col-md-6 mx-0 px-0 vorschaubild ${result ? "order-md-last" : ""}`}>
                          <img src={e.logo} className="d-md-none xyz logopic"/>
                          <Link to={e.slug}><img src={e.objectImage} style={{ objectFit: "contain", pointerEvents: "visible"  }} /></Link>
                        </div>
                        <div className={`col-md-6 mx-0 px-0 textpart ${result ? "order-md-first" : ""}`}>
                          <Introtext
                            skypic={e.logo}
                            skyhead={false}
                            titel={e.titel}
                            text={e.text}
                            linklabel="Mehr erfahren"
                            link={e.slug}
                          />
                        </div>
                      </div>
                    </ContentmoduleAbs>
                  </div>
                </div>
              </SectionWrap>
            </div>
          )
        })}
      </div>

      <WhatsNext text="Kontakt &rarr;" link="/kontakt" />

    </Layout>
  )
}

export default BTOBPlatformIndex
